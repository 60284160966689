import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type RouteTitle = Record<string, string>;

const DEFAULT_TITLE = 'Sitecore Cloud Portal';
const titles: RouteTitle = {
  '/': DEFAULT_TITLE,
  '/subscriptions': `Subscriptions - ${DEFAULT_TITLE}`,
  '/admin/members/page/1': `Team members - ${DEFAULT_TITLE}`,
  '/admin/invites/page/1': `Invitations - ${DEFAULT_TITLE}`,
  '/admin/multifactor-authentication': `Multi-factor authentication - ${DEFAULT_TITLE}`,
  '/profile': `Manage account - ${DEFAULT_TITLE}`,
  '/admin/sso': `Single sign-on - ${DEFAULT_TITLE}`,
  '/admin/support-access': `Sitecore Support access - ${DEFAULT_TITLE}`,
};

export const useSetDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = titles[location.pathname] ?? DEFAULT_TITLE;
  }, [location.pathname]);
};
