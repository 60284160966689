import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useCallback } from 'react';

type ContextMappingDrawerBodyPropTypes = {
  onCloseDrawer: () => void;
};

const AddEditContextMappingDrawerBody = ({
  onCloseDrawer,
}: ContextMappingDrawerBodyPropTypes) => {
  const onChange = useCallback(() => {
    //console.log('onChange triggered');
  }, []);

  const edgeEndpointOptions: { displayName: string; name: string }[] = [
    { displayName: 'Endpoint 1', name: 'https://endpoint1' },
    { displayName: 'Endpoint 2', name: 'https://endpoint2' },
    { displayName: 'Endpoint 3', name: 'https://endpoint3' },
  ];

  const cdpTenantOptions: { displayName: string; name: string }[] = [
    { displayName: 'SpinAir Production', name: 'SpinAir Production' },
    { displayName: 'SpinAir UAT', name: 'SpinAir UAT' },
  ];

  const searchTenantOptions: { displayName: string; name: string }[] = [
    { displayName: 'SpinAir Prod', name: 'SpinAir Prod' },
    { displayName: 'SpinAir UAT', name: 'SpinAir UAT' },
  ];

  return (
    <>
      <DrawerBody>
        <Text mb={5} data-testid='add-edit-context-mapping-drawer-description'>
          Choose the tenants you want to link together with context mapping.
        </Text>
        <FormControl py={2}>
          <FormLabel>
            <T _str='Edge endpoint' />
          </FormLabel>
          <Select
            name='edgeEndpoint'
            onChange={onChange}
            data-testid='add-edit-context-mapping-drawer-edge-endpoint-select'
            placeholder='Select'
          >
            {edgeEndpointOptions.map((opt) => (
              <option value={opt.name}>{opt.displayName}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl py={2}>
          <FormLabel>
            <T _str='CDP/Personalize tenant' />
          </FormLabel>
          <Select
            name='cdpTenant'
            onChange={onChange}
            data-testid='add-edit-context-mapping-drawer-cdp-tenant-select'
            placeholder='Select'
          >
            {cdpTenantOptions.map((opt) => (
              <option value={opt.name}>{opt.displayName}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl py={2}>
          <FormLabel>
            <T _str='Search tenant' />
          </FormLabel>
          <Select
            name='searchTenant'
            onChange={onChange}
            data-testid='add-edit-context-mapping-drawer-search-tenant-select'
            placeholder='Select'
          >
            {searchTenantOptions.map((opt) => (
              <option value={opt.name}>{opt.displayName}</option>
            ))}
          </Select>
        </FormControl>
      </DrawerBody>
      <DrawerFooter justifyContent='flex-end'>
        <ButtonGroup>
          <Button
            data-testid='add-edit-context-mapping-drawer-cancel-btn'
            type='button'
            variant='ghost'
            onClick={onCloseDrawer}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            data-testid='add-edit-context-mapping-drawer-save-btn'
          >
            Save
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

export default AddEditContextMappingDrawerBody;
