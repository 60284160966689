import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { T } from '@transifex/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ctxMappingRoute,
  getCtxMappingEditRoute,
} from '../../routes/ContextMappingDrawerRoute';
import DiscardChangesModal from '../../../common/components/DiscardModal/DiscardChangesModal';
import AddEditContextMappingDrawerBody from './AddEditContextMappingDrawerBody';

export const AddEditContextMappingDrawer = () => {
  const history = useHistory();
  // if path matches /admin/context-mapping/:id/edit, then isInEditMode is true
  const isInEditMode = Boolean(useRouteMatch(getCtxMappingEditRoute()));
  const { isOpen, onClose, onOpen: onOpenDiscard } = useDisclosure();
  const [isDirty] = useState(false);

  const onCloseDrawer = useCallback(() => {
    if (isDirty) return onOpenDiscard();
    history.push({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, isDirty, onClose, onOpenDiscard]);

  const onDiscard = useCallback(() => {
    onClose();
    history.push({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
  }, [history, onClose]);

  function handleSubmit() {
    //console.log('handleSubmit triggered');
  }

  return (
    <>
      <Drawer onClose={onCloseDrawer} isOpen={true} size='lg'>
        <form
          onSubmit={handleSubmit}
          data-testid='add-edit-context-mapping-drawer-form'
        >
          <DrawerOverlay />
          <DrawerContent
            data-testid='add-edit-context-mapping-drawer-content'
            data-behavior-analytics-feature='Add edit context mapping drawer'
          >
            <DrawerHeader display='flex' alignItems='center'>
              <T _str={`${isInEditMode ? 'Edit' : 'Add'} context mapping`} />
            </DrawerHeader>
            <AddEditContextMappingDrawerBody onCloseDrawer={onCloseDrawer} />
          </DrawerContent>
        </form>
      </Drawer>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={onDiscard}
      />
    </>
  );
};
