import { Box, Text } from '@chakra-ui/react';
import { IResponse } from '@sitecore-ui/portal-singular';
import { AxiosResponse } from 'axios';
import {
  DataTable,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import { useCallback, useMemo, useState } from 'react';
import { useGetAuditLogs } from './hooks/useGetAuditLogs';
import NoLogs from './NoLogs';
import { Log } from './types';
import { tableOptions, getTableData } from './utils';

const sortBy: DataTableSortingRule[] = [];

const defaultPage = 1;
const defaultPageSize = 10;

const SupportAccessTable = () => {
  const [page, setPage] = useState(defaultPage);
  const { data, isLoading } = useGetAuditLogs({
    page,
    pageSize: defaultPageSize,
  });
  const responseData = data as AxiosResponse<IResponse<Log>> | undefined;
  const logs = useMemo(
    () => responseData?.data?.data ?? [],
    [responseData?.data?.data],
  );
  const totalCount = responseData?.data?.totalCount ?? 0;

  const tableData = useMemo(() => getTableData(logs), [logs]);

  const onChange = useCallback(
    ({ page }: { page: number }) => setPage(page),
    [setPage],
  );

  const pagination = totalCount < defaultPageSize ? 'hidden' : 'complex';

  return logs?.length || isLoading ? (
    <Box w='full'>
      <DataTable
        columns={tableOptions.columns}
        pagination={pagination}
        alignPagination='right'
        totalItems={totalCount}
        data={tableData}
        onChange={onChange}
        page={page}
        pageSize={defaultPageSize}
        isLoading={isLoading}
        sortBy={sortBy}
        theaderProps={{
          'data-behavior-analytics-id': 'support access sorting',
        }}
        paginationProps={{
          'data-behavior-analytics-feature': ' support access pagination',
        }}
        data-behavior-analytics-feature='support access table'
      />
      <Text variant='small'>Available data of the last 90 days</Text>
    </Box>
  ) : (
    <NoLogs />
  );
};

export default SupportAccessTable;
