import { hostnames } from '../common/config/envConfig';

export function getContextUrl() {
  const contextBaseUrl = {
    [hostnames.localhost]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.internal]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.staging]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.beta]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames['pre-production']]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames.production]:
      'https://edge-platform-context-api.sitecorecloud.io/api/context/user/v1',
  }[window.location.hostname];

  return contextBaseUrl || '';
}
