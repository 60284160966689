import { createFeatureFlags } from '@sitecore/featureflags';

export const {
  Feature,
  FlagsProvider,
  useFeature,
  useFeatures,
  withFeature,
  behindFeature,
} = createFeatureFlags({
  MFA: true,
  SNOW: true,
  APP_SWITCHER: true,
  EXPORT_MEMBERS: true,
  INVITATIONS: true,
  SSO: true,
  NEW_FEATURE_ANNOUNCEMENT: false,
  SUBSCRIPTIONS: false,
  SAML: false,
  OPENID: false,
  PROVISION_APPS: false,
  RECOMMENDATIONS: false,
  SUPPORT_ACCESS: false,
  CONTEXT_MAPPING: false,
});

export type FeatureFlags = ReturnType<typeof useFeatures>;
export type FeatureName = keyof FeatureFlags;
