import { Heading, Flex, ButtonGroup, Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import React, { ReactNode, useMemo } from 'react';
import {
  generateContextsTableData,
  getTableOptions,
} from './helpers/contextMappingTableHelper';
import { DataTable } from '../../../common/components/DataTable';
import { Feature } from '../../../../lib/featureFlags/features';
import { useMainState } from '../../../main/context';
import { PortalIcon } from '../../../common/components/PortalIcon';
import { mdiPlus } from '@mdi/js';
import { useOrgEnvironmentDeps } from '../../../common/utils/orgEnvironments';
import {
  ContextMappingDrawerRoute,
  ctxMappingCreateRoute,
  getCtxMappingEditRoute,
} from '../../routes/ContextMappingDrawerRoute';
import { AddEditContextMappingDrawer } from '../drawer/AddEditContextMappingDrawer';
import { history } from '../../../common/history/history';
import { SitecoreContextType } from '../../types/types';
import { useGetListSitecoreContexts } from '../../api/useGetListSitecoreContexts';

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

export const ContextMappingTable = ({
  contexts = [],
}: {
  contexts: SitecoreContextType[];
}) => {
  const { withXmCloudContext } = useMainState();
  const { tenantsData } = useOrgEnvironmentDeps();

  const { data: fetchedContexts, isLoading: sitecoreContextsLoading } =
    useGetListSitecoreContexts();

  const tableData = useMemo(
    () => generateContextsTableData(fetchedContexts || contexts, tenantsData),
    [fetchedContexts, contexts, tenantsData],
  );

  const options = useMemo(
    () => getTableOptions({ withXmCloudContext: withXmCloudContext || false }),
    [withXmCloudContext],
  );

  return (
    <>
      <Flex direction='column'>
        <Header>
          <Heading as='h1'>
            <T _str='Context Mapping' />
          </Heading>
          <ButtonGroup>
            <Feature name='CONTEXT_MAPPING'>
              <Button
                variant='solid'
                onClick={() =>
                  history.push({
                    pathname: ctxMappingCreateRoute,
                    search: history.location.search,
                  })
                }
                leftIcon={<PortalIcon path={mdiPlus} />}
                data-testid='add-context-mapping-toggle'
                data-behavior-analytics-id='Add context mapping button - Context Mapping page'
              >
                <T _str='Add context mapping' />
              </Button>
            </Feature>
          </ButtonGroup>
        </Header>
        <div>
          <DataTable
            columns={options.columns}
            //@ts-ignore
            rowActions={options.rowActions}
            // eslint-disable-next-line no-empty-function
            onChange={() => {}}
            isLoading={sitecoreContextsLoading}
            data={tableData}
            theaderProps={{ 'data-behavior-analytics-id': 'contexts sorting' }}
            pagination='hidden'
            data-behavior-analytics-feature='contexts table'
          />
        </div>
      </Flex>
      <ContextMappingDrawerRoute
        exact
        path={ctxMappingCreateRoute}
        component={AddEditContextMappingDrawer}
      />
      <ContextMappingDrawerRoute
        exact
        path={getCtxMappingEditRoute()}
        component={AddEditContextMappingDrawer}
      />
    </>
  );
};
