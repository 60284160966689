import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PortalIcon } from '../../../../common/components/PortalIcon';
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js';
import { history } from '../../../../common/history/history';
import { getCtxMappingEditRoute } from '../../../routes/ContextMappingDrawerRoute';
import {
  SitecoreContextMappingType,
  SitecoreContextType,
  TenantType,
} from '../../../types/types';

export const contextMappingTableButtonsAccessor = (
  contextId: string,
  contextType: string,
) => {
  return (
    <ButtonGroup flexDir='row' justifyContent='flex-end' size='sm' width='full'>
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='edit context mapping'
        icon={<PortalIcon path={mdiPencilOutline} />}
        onClick={() =>
          history.push({
            pathname: getCtxMappingEditRoute(contextId),
            search: history.location.search,
          })
        }
      />
      {contextType === 'custom' && (
        <IconButton
          variant='ghostColorOnHover'
          colorScheme='danger'
          aria-label='delete context mapping'
          icon={<PortalIcon path={mdiTrashCanOutline} />}
        />
      )}
    </ButtonGroup>
  );
};

type GetTableOptions = {
  withXmCloudContext: boolean;
};

export const getTableOptions = ({ withXmCloudContext }: GetTableOptions) => {
  return {
    columns: [
      {
        Header: <T _str='Context ID' />,
        accessor: 'sitecoreContextId',
      },
      ...(withXmCloudContext
        ? [
            {
              Header: <T _str='Edge Endpoint' />,
              accessor: 'edgeEndpoint',
            },
          ]
        : []),
      {
        Header: <T _str='Personalize Tenant' />,
        accessor: 'personalizeTenant',
      },
      {
        Header: <T _str='Search Tenant' />,
        accessor: 'searchTenant',
      },
      {
        id: 'context-mapping-table-buttons',
        Header: '',
        accessor: ({ contextId, type }: SitecoreContextMappingType) =>
          contextMappingTableButtonsAccessor(contextId, type),
        disableSortBy: true,
      },
    ],
  };
};

export const mapPersonalizeTenant = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  const validTypes = ['personalize', 'cdp', 'personalizeembedded'];
  const isValid =
    validTypes.includes(resourceType) && validTypes.includes(productCode);
  return isValid ? tenant.displayName : '';
};

export const mapEdgeEndpoint = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  if (resourceType === 'xmcloud' && productCode === 'xmcloud') {
    switch (tenant.labels.CustomerEnvironmentType?.toLowerCase()) {
      case 'prod':
        return 'Live';
      case 'nonprod':
        return 'Preview';
      default:
        return '';
    }
  }
  return '';
};

export const mapSearchTenant = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  if (resourceType === 'search' && productCode === 'search') {
    return tenant.displayName;
  }
  return '';
};

export const generateContextsTableData = (
  contexts: SitecoreContextType[] = [],
  tenantsData: TenantType[] = [],
) => {
  if (contexts.length === 0 && tenantsData.length === 0) {
    return [];
  }

  return contexts.map((context) => {
    let edgeEndpoint = '';
    let personalizeTenant = '';
    let searchTenant = '';

    context.resources.forEach((resource) => {
      const tenant = tenantsData.find(
        (tenant) => tenant.id === resource.tenantId,
      );

      if (tenant) {
        const resourceType = resource.type.toLowerCase();
        const productCode = tenant.labels.ProductCode.toLowerCase();

        const mappedPersonalizeTenant = mapPersonalizeTenant(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedPersonalizeTenant) {
          personalizeTenant = mappedPersonalizeTenant;
        }

        const mappedEdgeEndpoint = mapEdgeEndpoint(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedEdgeEndpoint) {
          edgeEndpoint = mappedEdgeEndpoint;
        }

        const mappedSearchTenant = mapSearchTenant(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedSearchTenant) {
          searchTenant = mappedSearchTenant;
        }
      }
    });

    return {
      sitecoreContextId: context.sitecoreContextId,
      organizationId: context.organizationId,
      type: context.type,
      accountId: context.accountId,
      edgeEndpoint,
      personalizeTenant,
      searchTenant,
    };
  });
};
