import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { getContextUrl } from '../utils';

export const useGetListSitecoreContexts = () => {
  const url = `${getContextUrl()}/contexts`;
  return useAuthQuery(['context/list'], async (axiosInstance) => {
    const response = await axiosInstance?.get(url);
    return response?.data;
  });
};
