import {
  EProductCodes,
  ITokenClaims,
  TokenCustomClaimKeysEnum,
  UserEnvironmentObject,
} from '@sitecore-ui/portal-singular';
import {
  ContextUser,
  ContextUserApplication,
  Organization,
  UserApplicationRole,
} from 'gql/graphql';

export function adaptGraphQLDataToUserEnvironmentObject({
  tenantsQueryData,
  userOrgDepsData,
  claims,
  isXMCloudExist,
}: {
  tenantsQueryData?: {
    user?: {
      applications?: {
        nodes?: ContextUserApplication[];
      };
    };
  };
  userOrgDepsData?: {
    user?: Partial<ContextUser>;
    organization?: Partial<Organization>;
  };
  claims?: ITokenClaims;
  isXMCloudExist: boolean;
}): UserEnvironmentObject {
  const orgRoles =
    claims?.[TokenCustomClaimKeysEnum.ROLES]
      ?.filter((role: string) => role.includes('[Organization]'))
      .map((role: string) => {
        const [, name] = role.split('\\');
        return {
          organizationId: claims?.[TokenCustomClaimKeysEnum.ORG_ID] ?? '',
          role: name,
          scope: 'Organization',
          tenantId: null,
        };
      }) ?? [];

  const appRoles =
    userOrgDepsData?.user?.applications?.nodes?.flatMap(
      // @ts-ignore
      (node: { assignedRoles: { nodes: UserApplicationRole[] } }) =>
        node?.assignedRoles?.nodes,
    ) ?? [];

  const transformedAppRoles = appRoles.map(
    (item: {
      organizationId: any;
      role: any;
      productCode: any;
      applicationId: any;
    }) => ({
      organizationId: item?.organizationId ?? '',
      role: item?.role ?? '',
      scope: item?.productCode ?? '',
      tenantId: item?.applicationId ?? '',
    }),
  );

  const nodes: ContextUserApplication[] =
    tenantsQueryData?.user?.applications?.nodes ?? [];

  return {
    tenantsData: nodes.map((e) => {
      return {
        id: e?.id,
        name: e?.appearance?.web.actions?.nodes?.[0]?.name ?? '',
        displayName: e?.appearance?.web.actions?.nodes?.[0]?.displayName ?? '',
        organizationId: e?.organizationId,
        state: e?.state ?? '',
        labels: {
          CustomerEnvironmentType: '',
          Environment: '',
          ProductCode: e.productCode as EProductCodes,
          Region: '',
        },
        annotations: {
          url: '',
        },
      };
    }),
    userOrgData: [
      {
        id: userOrgDepsData?.organization?.name ?? '',
        createdBy: '',
        displayName: userOrgDepsData?.organization?.displayName ?? '',
        phoneNumber: '',
        preferredLanguage: '',
        region: '',
      },
    ], // reference the correct path from the query
    userRoleData: [...orgRoles, ...transformedAppRoles], // reference the correct path from the query
    xmCloudContext: isXMCloudExist, // reference the correct path from the query
  };
}
